import React from 'react'
import Button from '../common/button/Button.component'
import Loading from '../common/Loading.component'
import PropTypes, { object } from 'prop-types'
import { makeStyles, Box } from '@material-ui/core'
import CarInfoCard from './CarInfoCard.component'
import { theme as mainTheme } from '../testUtils'
import CustomTypography from '../common/CustomTypography.component'
import Slider from 'react-slick'
import { goToCatalogAutosList } from '../../api/navigation'

const useStyle = makeStyles((theme) => ({
  container: {
    margin: '0 auto',
    maxWidth: '70rem',
    width: 'fit-content',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      width: '100%',
    },
  },
  buttonContainer: {
    paddingTop: '34px',
    paddingBottom: 0,
    marginBottom: 0,
    justifyContent: 'center',
  },
  carouselContainer: {
    '& .slick-list': {
      width: ({ isOneCar }) => isOneCar ? '280px' : 'inherit',
      margin: '0 auto',
    },
    '& .slick-slide': {
      padding: '0 8px',
    },
    '& .prevArrow': {
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      zIndex: '1',
      left: '-57px',
    },
    '& .nextArrow': {
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      zIndex: '1',
      right: '-57px',
    },
  },
  loading: {
    marginBottom: theme.spacing(4),
  },
}))

const NextArrow = (props) => {
  const { onClick } = props
  return (
    <div className="nextArrow">
      <Button type="secondary" icon="right" children="" onClick={onClick} />
    </div>
  )
}

const PrevArrow = (props) => {
  const { onClick } = props
  return (
    <div className="prevArrow">
      <Button type="secondary" icon="left" children="" onClick={onClick} />
    </div>
  )
}

const SegmentedCars = ({
  loading = false,
  cars = [],
  onSegmentClick = () => {},
}) => {
  const classes = useStyle({ isOneCar: cars.length === 1 })
  const showCarrouselArrows = cars.length > 4
  const slidesToShow = cars.length > 4 ? 4 : cars.length
  const centerMode = cars.length === 1
  const carouselSetting = {
    slidesToShow,
    slidesToScroll: slidesToShow,
    arrows: showCarrouselArrows,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: mainTheme.breakpoints.values.sm,
        settings: {
          centerMode,
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: true,
          centerPadding: '0px',
        },
      },
    ],
  }

  const handleCardClick = (car) => {
    const segment = car.segment?.name || ''
    goToCatalogAutosList({
      marca: { label: car.brand?.name, value: car.brand.id[0] },
      modelo: { label: car.model?.name, value: car.model.id[0] },
      segmento: { label: segment, value: segment },
    })
  }

  return (
    <div className={classes.container}>
      {loading ? (
        <>
          <Loading className={classes.loading} />
          <CustomTypography variant={'body2'}>Cargando...</CustomTypography>
        </>
      ) : (
        <div className={classes.carouselContainer} >
          <Slider {...carouselSetting}>
            {cars.map((car, index) => {
              return (
                <CarInfoCard
                  id='catalogo-card-tipoDeAutos'
                  key={index}
                  car={car}
                  onCardClick={handleCardClick}
                />
              )
            })}
          </Slider>
        </div>
      )}
      <Box item container className={classes.buttonContainer}>
        <Button type="secondary" onClick={onSegmentClick} icon="right" id='catalogo-btn-verTodos'>
          <span id='catalogo-btn-txt-verTodos'>Ver todos</span>
        </Button>
      </Box>
    </div>
  )
}

SegmentedCars.propTypes = {
  loading: PropTypes.bool,
  cars: PropTypes.arrayOf(object),
  onSegmentClick: PropTypes.func,
}

export default SegmentedCars