import React from 'react';
import MuiTab from '@material-ui/core/Tab';
import { withStyles } from '@material-ui/core/styles';

const Tab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    flexGrow: 1,
    fontSize: theme.typography.size.small,
    fontWeight: theme.typography.fontWeightRegular,
    '&:hover': {
      color: theme.palette.primary.main,
      opacity: 1,
    },
  },
  selected: {},
}))((props) => <MuiTab disableRipple {...props} />);

export default Tab;
