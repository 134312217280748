import React from 'react';
import { Router, useLocation } from '@reach/router';
import { CatalogDetailPage } from '../components/catalog-detail/CatalogDetailPage';
import { CatalogMasterContainer } from '../containers/catalogo-autos/catalogoMasterContainer';

const CatalogoMaestroPage = (props) => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const showResults = params.has('showResults');
  const q = params.get('q');
  const productId = params.get('productId');

  return (
    <Router basepath="/catalogo-autos">
      {productId && <CatalogDetailPage {...props} path="/" />}
      {!productId && <CatalogMasterContainer
        {...props}
        path="/"
        showResults={showResults}
        querySearch={q}
      />}
    </Router>
  );
};

export default CatalogoMaestroPage;
