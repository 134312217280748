import React, { useState, useEffect, useRef, useCallback } from 'react';
import Box from '@material-ui/core/Box';
import Text from '../../common/text.component';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme, useMediaQuery } from '@material-ui/core';
import ColorList from './ColorList.component';
import clsx from 'clsx';


const useStyles = makeStyles(theme => ({
  info: {
    padding: '0',
    width: '40%',
    flex: '1 1 50%',
    [theme.breakpoints.up('sm')]: {
      flex: '1 1 25%',
      width: '8.688rem',
    }
  },
  label: {
    fontWeight: theme.typography.fontWeightLight,
    fontSize: theme.typography.size.smaller,
    paddingBottom: '0.5rem',
  },
  bold: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.size.small,
  },
  infoContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    margin: '1rem 1rem 0.5rem 1rem',
    paddingBottom: '2rem',
    borderBottom: `1px solid ${theme.palette.grayScale.g200}`,
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-start',
      marginLeft:'50px',
    }
  },
  container: {
    flexDirection: 'column',
    margin: '1rem 1rem 0.5rem 1rem',
    paddingBottom: '2rem',
    borderBottom: `1px solid ${theme.palette.grayScale.g200}`,
    [theme.breakpoints.up('md')]: {
      alignItems: 'flex-start',
      marginLeft:'50px',
    }
  },
  colorItem: {
    padding: '1rem 0.5rem',
    [theme.breakpoints.up('md')]: {
      padding: '1rem',
    }
  },
  subtitle: {
    marginBottom: '1rem',
  },
  hyperLinkContainer: {
    color: `${theme.palette.primary.main}`
  },
  descriptionContainer: {
    margin: 0
  },
  lineClamp4: {
    lineClamp: 6,
    boxOrient: 'vertical',
    display: '-webkit-box',
    overflow: 'hidden',
  },
  expandButtonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  expandButton: {
    color: `${theme.palette.primary.main}`,
    cursor: 'pointer',
    fontSize: '1.25rem'
  },
  expandButtonMobile: {
    color: `${theme.palette.primary.main}`,
    cursor: 'pointer',
    fontSize: '0.75rem'
  }
}));

const TabDetail = ({ car, loadingCar }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();
  let carDescription = car.description ? car.description : ' ';
  const [carDescriptionToRender, setCarDescriptionToRender] = useState([carDescription]);
  const [expandButtonLabel, setExpandButtonLabel] = useState('');
  const [isExpanded, setIsExpanded] = useState(false);
  const [showExpandButton, setShowExpandButton] = useState(false);
  const descriptionContainer = useRef(null);
  const regexToDetectUrls = /(http|https)?(:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{2,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/g
  const regexToDetectHttp = /(http|https)/g;

  const extractLinks = useCallback((text) => {
    const hasHyperLink = regexToDetectUrls.test(text);
    if(hasHyperLink) {
      let [link] = text.match(regexToDetectUrls);
      const includeHttps = link.match(regexToDetectHttp);
      const HTTPS = ' https://';
      if(!includeHttps){
        link =  HTTPS + link;
      }
      const indexToCute = text.indexOf(link);
      const indexTofinish = indexToCute + link.length;
      if(indexToCute > 0) {
        const firstElement = text.substring(0, indexToCute);
        const linkElement = <a href={link} className={classes.hyperLinkContainer} target="_blank">{link}</a>;
        const restOfTheText = text.substring(indexTofinish);
        return [firstElement, linkElement, ...extractLinks(restOfTheText)];
      } else {
        const linkElement = <a href={link} className={classes.hyperLinkContainer} target="_blank">{link}</a>;
        const restOfTheText = text.substring(indexTofinish);
        return [linkElement, ...extractLinks(restOfTheText)];
      }
    } else {
      return [text];
    }
  }, [regexToDetectUrls])

  useEffect(() => {
    if(descriptionContainer?.current?.offsetHeight > 121 && !showExpandButton ) {
      setShowExpandButton(true);
    } else {
      setShowExpandButton(false);
    }
  }, []);

  useEffect(() => {
    setExpandButtonLabel(isExpanded ? 'Ver menos' : 'Ver más');
  }, [isExpanded]);

  useEffect(() => {
    if(carDescription?.length > 0) {
      const descriptionFormater = extractLinks(carDescription);
      setCarDescriptionToRender(descriptionFormater);
    }
  }, [carDescription])


  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" className={classes.infoContainer}>
        <Box className={classes.info}>
          <Box marginBottom="0.5rem">
            <Text light bold g500>Segmento</Text>
          </Box>
          <Text>{car.segment?.name || '--'}</Text>
        </Box>
        <Box className={classes.info}>
          <Box marginBottom="0.5rem">
            <Text bold light g500>Transmisión</Text>
          </Box>
          <Text>{car.transmission || '--'}</Text>
        </Box>
        <Box className={classes.info}>
          <Box marginBottom="0.5rem">
          </Box>
        </Box>
      </Box>
      {!loadingCar && car.is0km && (
        <Box className={classes.container}>
          <ColorList colorList={car.colors} isDetailSection/>
        </Box>
      )}
      { car.description && (
        <Box display="flex" className={classes.container}>
          <Text bold className={classes.subtitle}>Descripción</Text>
          <p ref={descriptionContainer} className={clsx(classes.descriptionContainer ,{[classes.lineClamp4]: !isExpanded && showExpandButton})}>
            {carDescriptionToRender.map(el => <>{el}</>)}
          </p>
          { showExpandButton &&
            <div className={classes.expandButtonContainer}>
              <span className={isMobile ? classes.expandButtonMobile : classes.expandButton} onClick={() => setIsExpanded(!isExpanded)}>
                {expandButtonLabel}...
              </span>
            </div>
          }
        </Box>
      )
      }
    </Box>
  );
};

export default TabDetail;
