import React, { useState } from 'react'

import CatalogMasterTitle from '../../components/catalogMaster/CatalogMasterTitle'
import SearchBar from '../../components/common/forStorybook/SearchBar.component'
import { useEffect } from 'react'

const PLACEHOLDER = 'Ingresá marca, modelo o versión'

const CatalogMasterSearch = ({ onKeyDownSearch, searchParam, clearSearchParam }) => {
  const [searchValue, setSearchValue] = useState(searchParam)

  useEffect(() => {
    if (searchParam === '') {
      setSearchValue('')
    }
  }, [searchParam])

  const onChangeSearch = (e) => {
    if (e === '') {
      setSearchValue('')
      clearSearchParam()
      return
    }

    const value = e.target.value
    setSearchValue(value)
  }

  return (
    <>
      <CatalogMasterTitle />
      <SearchBar
        autocomplete={false}
        disableClearable={true}
        receivedValue={searchValue}
        onKeyDownInput={onKeyDownSearch}
        onChangeInput={onChangeSearch}
        placeholder={PLACEHOLDER}
        id="catalogo-search-buscadorGeneral"
        noOptionsText={''}
      />
    </>
  )
}

export default CatalogMasterSearch
