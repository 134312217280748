import React, { useState } from 'react';
import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { useLazyQuery } from '@apollo/react-hooks';
import CatalogMasterFilters from './CatalogMasterFilters.container';
import CatalogMasterCards from './CatalogMasterCards.container';
import CatalogMasterSearch from '../../components/catalogMaster/CatalogMasterSearch.component';
import { GET_DATAMASTER_CARS } from '../../queries';
import { formaterNumber } from '../../utils/currency.utils';

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    gap: '2rem',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  mainTitle: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  mainFilters: {
    minWidth: '293px',
    justifyContent: 'start',
  },
  mainCards: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
    width: '100%',
    maxWidth: '940px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  bodyTitleCards: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'start',
    alignItems: 'center',
  }
}));

const ListContainer = ({ querySearch = '', pageContext }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();

  const fullParams = new URLSearchParams(window.location.href);
  const keyword = fullParams.get('keyword');
  const [searchParam, setSearchParam] = useState(querySearch || keyword)
  const [cars, setCars] = useState([])
  const [clearFlag, setClearFlag] = useState(false)
  const [pagination, setPagination] = useState({
    total: 1,
    pages: 1,
    hasMore: false,
    actualPage: 1,
  });
  const [selFilters, setSelFilters] = useState({})

  const [getDatamasterCars, { loading, refetch }] = useLazyQuery(
    GET_DATAMASTER_CARS,
    {
      variables: {
        search: searchParam,
        page: 1,
      },
      onCompleted: (data) => {
        setCars(data.carsMaster.results);
        setPagination(data.carsMaster.pagination);
      },
      fetchPolicy: 'network-only',
    }
  );

  const onKeyDownSearch = (e) => {
    if (e.keyCode === 13) {
      setSearchParam(e.target.value);
      setClearFlag(true);
      refetch();
      e.target.blur() // saco el foco para que se cierre el teclado en mobile
    }
  };

  const onChangeFilters = (filters) => {
    if (Object.keys(filters).length) {
      setSearchParam('')
    }
    getDatamasterCars({
      variables: {
        ...filters,
      },
    });
  };

  const paginationTotal = cars.length ? formaterNumber(pagination.total) : 0;

  const catalogFilter = <div className={classes.mainFilters}>
    <CatalogMasterFilters
      onChange={onChangeFilters}
      total={paginationTotal}
      clearSearchParam={() => setSearchParam('')}
      searchParam={searchParam}
      clearFlag={clearFlag}
      setClearFlag={setClearFlag}
      selFilters={selFilters}
      setSelFilters={setSelFilters}
    />
  </div>;

  return (
    <div className={classes.mainContainer}>
      {!isMobile && catalogFilter}
      <div className={classes.bodyTitleCards} >
        <div className={classes.mainTitle}>
          <CatalogMasterSearch
            onKeyDownSearch={onKeyDownSearch}
            searchParam={searchParam}
            clearSearchParam={() => setSearchParam('')}
          />
        </div>
        {isMobile && catalogFilter}
        <div className={classes.mainCards}>
          <CatalogMasterCards
            cars={cars}
            pagination={pagination}
            setPagination={setPagination}
            loading={loading}
            getDatamasterCars={getDatamasterCars}
            total={paginationTotal}
            selFilters={selFilters}
            pageContext={pageContext}
          />
        </div>
      </div>
    </div>
  );
};

export default ListContainer;
