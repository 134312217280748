import React from 'react';
import Tabs from '../common/tabs/Tabs.component';
import Tab from '../common/tabs/Tab.component';
import TabDetail from './tabDetalle/TabDetail.component';
import Box from '@material-ui/core/Box';
import DataSheet from './DataSheet/DataSheet.component';
import Link from '../common/Link.component';
import { makeStyles } from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Text from '../common/text.component';

const TEXT_DETALLE = 'Detalle';
const TEXT_FICHA = 'Ficha técnica';
const SUGERIR_BTN_TEXT = 'Sugerir cambios en la ficha técnica';

const useStyles = makeStyles(theme => ({
  link: {
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
  },
  text: {
    marginRight: '1rem',
  },
  linkContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '8px',
    margin: '0 50px',
    [theme.breakpoints.down('xs')]: {
      whiteSpace: 'nowrap',
      margin: '0 auto',
    },
  },
  tab: {
    [theme.breakpoints.down('sm')]: {
      flex: 1,
      minHeight: theme.spacing(7.5),
      '& .MuiTab-wrapper': {
        fontSize: '0.875rem'
      }
    }
  },
  tabContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(5)
    }
  }
}));

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-informativo-${index}`}
      aria-labelledby={`tab-informativo-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={1}>{children}</Box>
      )}
    </div>
  );
};

const InformativeTabs = ({ car, isLoggedIn, onSuggest, loadingCar }) => {
  const [value, setValue] = React.useState(0);
  const classes = useStyles();

  function handleSuggest () {
    onSuggest();
  }

  function handleChange(event, newValue) {
    setValue(newValue);
  }

  function a11yProps(index) {
    return {
      id: `tab-informativo-${index}`,
      'aria-controls': `tab-informativo-${index}`,
    };
  }

  return (
    <Box className={classes.tabContainer}>
      <Tabs
        value={value}
        indicatorColor="primary"
        onChange={handleChange}
        aria-label="tabs informativos"
        data-test-id="informative-tabs"
      >
        <Tab className={classes.tab} label={TEXT_DETALLE} {...a11yProps(0)}/>
        <Tab className={classes.tab} label={TEXT_FICHA} {...a11yProps(1)}/>
      </Tabs>
      <TabPanel value={value} index={0}>
        <TabDetail car={car} loadingCar={loadingCar}/>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <DataSheet car={car} />
      </TabPanel>
      {isLoggedIn &&
      <Box className={classes.linkContainer}>
        <Link className={classes.link} inverted onClick={handleSuggest}>
          <Text bold className={classes.text}>{SUGERIR_BTN_TEXT}</Text>
          <ArrowForwardIosIcon fontSize="small" />
        </Link>
      </Box>
      }
    </Box>
  );
};

export default InformativeTabs;
