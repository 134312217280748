
const FUEL_TYPES_OPTIONS = [
  { label: 'Nafta', value: 'Nafta' },
  { label: 'Diesel', value: 'Diesel' },
];

const TRANSMISSION_TYPES_OPTIONS = [
  { label: 'Automático', value: 'Automático' },
  { label: 'Manual', value: 'Manual' },
];

export const CATALOG_FILTERS_SETTINGS = {
  segments: {
    name: 'Tipo de auto',
    options: [],
    selected: null,
  },
  brands: {
    name: 'Marca',
    options: [],
    selected: null,
  },
  models: {
    name: 'Modelo',
    options: [],
    selected: null,
    noOptionsMessage: 'Seleccioná una marca',
  },
  fuel: {
    name: 'Combustible',
    options: FUEL_TYPES_OPTIONS,
    selected: null,
  },
  transmission: {
    name: 'Transmisión',
    options: TRANSMISSION_TYPES_OPTIONS,
    selected: null,
  },
};
