import React from 'react';
import { navigate } from 'gatsby';
import SearchBar from '../../components/common/forStorybook/SearchBar.component';
import clsx from 'clsx';

const PLACEHOLDER = 'Ingresá marca, modelo o versión';

export const SearchBarSection = ({
  classes,
  location,
  searchValue = '',
  setSearchValue = () => {},
}) => {

  const navigateToShowResults = () => {
    const url = new URL(`${location.origin}${location.pathname}`);
    url.searchParams.append('showResults', true);
    url.searchParams.append('q', searchValue);
    navigate(url.href);
  };

  const onChangeSearch = (e) => {
    const value = e?.target?.value;
    setSearchValue(value || '');
  };

  const onSubmit = (e) => {
    e.preventDefault()
    if (!searchValue) return

    navigateToShowResults();
  };

  return (
    <form onSubmit={onSubmit} style={{width: '100%', display: 'flex'}}>
    <SearchBar
      autocomplete={false}
      disableClearable={true}
      receivedValue={searchValue}
      onChangeInput={onChangeSearch}
      placeholder={PLACEHOLDER}
      classNameTextfield={clsx('catalog-master-container', classes.searchBar)}
      id="catalogo-search-buscadorGeneral"
      noOptionsText={''}
    />
    </form>
  );
};
