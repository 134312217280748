import React from 'react';
import MuiChip from '@material-ui/core/Chip';

const Chip = ({ className, ...otherProps }) => {
  return (
    <MuiChip
      className={className}
      {...otherProps}
    />
  );
};

export default Chip;
