import React from 'react';
import ListContainer from '../catalogMaster/List.container';
import { makeStyles } from '@material-ui/core';
import Layout from '../../components/layouts/layout.component';
import { NEW_PALETTE as palette } from '../../assets/theme';
import clsx from 'clsx';
import { CatalogMasterContainerMain } from './catalogoMasterContainerMain';

const PAGE_TITLE = 'Catálogo';

const useStyles = makeStyles((_theme) => ({
  catalogMasterContainer: {
    width: '100%',
    margin: '0 auto',
    padding: '1.75rem 1rem',
    marginBottom: 0,
    '& .MuiFormControl-root': {
      marginTop: '1rem',
      marginBottom: '1rem',
    },
  },
  subtitile: {
    padding: '1rem',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.02em',
  },
  searchingSection: {
    width: '100%',
    maxWidth: '52.125rem',
    backgroundColor: palette.neutral.gray01,
    marginTop: '1rem',
    padding: '1.9375rem 1.5rem 1.5rem 1.5rem',
    border: '1px solid',
    borderRadius: '32px',
    borderColor: palette.neutral.gray01,
    '& .MuiButton-root': {
      height: '48px',
      lineHeight: 'unset',
      minWidth: 'unset',
    },
    [_theme.breakpoints.down('sm')]: {
      '& .model-select': {
        marginTop: '1rem',
      },
    },
  },
  searchBar: {
    maxWidth: '52.125rem',
    margin: '0 auto',
    '& .MuiInputBase-input': {
      fontSize: '0.875rem',
    },
  },
  searchingSectionSelect: {
    minWidth: '150px',
    '& .MuiFormControl-root': {
      marginTop: '0',
      marginBottom: '0',
    },
    '& .MuiOutlinedInput-root': {
      borderColor: palette.neutral.gray03,
    },
  },
  moreModelSection: {
    textAlign: 'center',
    width: '100%',
    margin: '4.5rem 0',
    marginBottom: '32px',
    '& .select-container': {
      display: 'flex',
      justifyContent: 'center',
      marginTop: '3rem',
      marginBottom: '2rem',
    },
    [_theme.breakpoints.down('sm')]: {
      marginTop: '24px',
    },
  },
  segmentSelect: {
    maxWidth: '19.3125rem',
    textAlign: 'center',
    '& .MuiFormControl-root': {
      position: 'relative',
    },
    '& .MuiTypography-root': {
      position: 'absolute',
      top: '-1.5rem',
    },
  },
  catalogoBtnBuscar: {
    [_theme.breakpoints.down('sm')]: {
      marginTop: '12px',
    },
  }
}));

export const CatalogMasterContainer = ({
  location,
  pageContext,
  showResults,
  querySearch,
}) => {
  const classes = useStyles();

  return (
    <Layout
      title={PAGE_TITLE}
      location={location}
      pageContext={pageContext}
      showSubHeader
    >
      <div
        className={clsx(
          'catalog-master-container',
          classes.catalogMasterContainer
        )}
      >
        {showResults ? (
          <ListContainer
            location={location}
            pageContext={pageContext}
            querySearch={querySearch}
          />
        ) : (
          <CatalogMasterContainerMain location={location} classes={classes} />
        )}
      </div>
    </Layout>
  );
};
