import React  from 'react'
import CustomTypography from '../common/CustomTypography.component'
import Select from '../common/forStorybook/Select.component'
import SegmentedCarsContainer from '../../containers/catalogMaster/SegmentedCars.container'
import PropTypes from 'prop-types'

const MoreModelsSection = ({
  classes,
  segments = [],
  selectedSegment = {},
  setSegment = () => {},
  onSegmentClick = () => {},
  loadingSegments = false,
}) => {

  return (
    <div className={classes.moreModelSection}>
      <CustomTypography variant="h5">Descubrí más modelos</CustomTypography>
      <div className="select-container">
        <Select
          className={classes.segmentSelect}
          helperText={'Tipo de auto'}
          options={segments.map((b) => ({
            label: b.name,
            value: b.type
          }))}
          loading={loadingSegments}
          selectedOption={selectedSegment}
          setSelectedOption={(value) => {
            setSegment(value)
          }}
          getOptionLabel={(option) => option.label}
          disableClearable
        />
      </div>

      <SegmentedCarsContainer
        selectedSegment={selectedSegment}
        onSegmentClick={onSegmentClick}
      />

    </div>
  )
}
SegmentedCarsContainer.propTypes = {
  segments: PropTypes.array,
  selectedSegment: PropTypes.object,
  setSegment: PropTypes.func,
  onSegmentClick: PropTypes.func,
  loading: PropTypes.bool,
}
export default MoreModelsSection
