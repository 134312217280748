import React from 'react';
import { Box } from '@material-ui/core';
import SectionData from './SectionData.component';
import Text from '../../common/text.component';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles( theme => ({
  title: {
    '&:before': {
      content: '\'\'',
      position: 'relative',
      backgroundColor: theme.palette.grayScale.g200,
      width: '20px',
      display: 'block',
      top: '12px',
      left: '-25px',
      height: '1px',
    },
    paddingLeft: '20px',
  }
}));

export default function Section({ titulo, data }) {
  const classes = useStyles();
  return (<>
    { titulo && <Box marginBottom="1rem" className={classes.title}><Text light normal upperCase>{titulo}</Text></Box>}
    <Box
      display='flex'
      flexWrap="wrap"
      marginBottom="1rem"
      width='100%'
      justifyContent='space-between'
    >
      {data.map((sectionData, index) => (
        sectionData.label && sectionData.label.trim().length > 0 &&
        <SectionData key={`section-data-${index}`} {...sectionData} />
      ))}
    </Box>
  </>);
}
