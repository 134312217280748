import React from 'react';
import Box from '@material-ui/core/Box';
import Text from '../../common/text.component';
import ColorBox from '../../common/ColorBox.component';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  item: {
    flexBasis: '33.33%',
    padding: '1rem 0.5rem',
    marginTop: '1rem',
    [theme.breakpoints.up('md')]: {
      padding: 0,
      flexBasis: '20%',
    }
  },
  detailColorContainer: {
    flexDirection: 'column',
    textAlign: 'center',
    width: '100%',
    '& span': {
      marginTop: '18px',
    }
  }
}));

const ColorList = ({ colorList, isDetailSection = false }) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Text bold>Colores disponibles de fábrica</Text>
      <Box display="flex" flexWrap="wrap">
        {
          colorList?.map((item) =>
            <Box className={classes.item} key={`${item.name}-${item.color}`}  alignItems="center">
              <ColorBox color={item.name} hex={item.color} isDetailSection={isDetailSection} className={classes.detailColorContainer}/>
            </Box>
          )
        }
      </Box>
    </React.Fragment>
  );
};

export default ColorList;
