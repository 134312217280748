import React from 'react';
import Layout from '../layouts/layout.component';
import { CatalogDetailContainer } from '../../containers/catalog-detail/catalogDetail';

export const CatalogDetailPage = ({
  location,
  pageContext,
}) => {

  const [ newContext, setNewContext ] = React.useState(pageContext);

  const setBreadcrumb = ( crumbs = []) => {
    setNewContext({
      ...newContext,
      breadcrumb: {
        ...newContext.breadcrumb,
        crumbs: [...newContext.breadcrumb.crumbs, ...crumbs]
      }
    });
  };

  return (
    <Layout
      title='Carmuv'
      location={location}
      pageContext={newContext}
      showSubHeader
    >
      <div style={{ textAlign: 'center', width: '100%' }} >
        <CatalogDetailContainer pageContext={pageContext} setBreadcrumb={setBreadcrumb} />
      </div>
    </Layout>
  );
};
