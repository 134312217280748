import React from 'react';
import { Box, useMediaQuery, useTheme } from '@material-ui/core';
import Text from '../../common/text.component';

const parseValue = (value) => {
  if (typeof value === 'boolean') {
    return value ? 'SI' : 'NO';
  }
  return value;
};

export default function SectionData({ label, value }) {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div
      style={{
        width: isMobile ? '100%' : '23%',
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '.5rem',
      }}
    >
      <Box marginBottom="2px">
        <Text light>{label}</Text>
      </Box>
      <div style={!isMobile ? {
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignContent: 'end',
        flexWrap: 'wrap',
      } : null} >
        <Text bold small>{parseValue(value) || '--'}</Text>
      </div>
    </div>
  );
}
