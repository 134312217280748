import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';
import Select from '../../components/common/forStorybook/Select.component';
import Button from '../../components/common/button.component';
import { GET_BRANDS, GET_MODELS } from '../../queries';

export const SearchingSection = ({
  classes,
  handleSubmit = () => {},
  brand = null,
  setBrand = () => {},
  model = null,
  setModel = () => {},
}) => {
  const {
    data: brandData,
    loading: loadingBrands,
    error: errorBrands,
  } = useQuery(GET_BRANDS, { variables: { published: true } });

  const {
    data: modelData,
    loading: loadingModels,
    error: errorModels,
  } = useQuery(GET_MODELS, {
    variables: { brandId: brand?.value, published: true },
  });

  const brands = brandData?.brands || [];
  const models = modelData?.models || [];

  return (
    <div className={classes.searchingSection}>
      <Grid
        container
        direction="row"
        alignItems="center"
        spacing={2}
      >
        <Grid item xs={12} md={5}>
          <Select
            className={classes.searchingSectionSelect}
            label='Marca'
            options={brands.map((b) => ({
              label: b.name,
              value: b.id[0],
            }))}
            loading={loadingBrands}
            selectedOption={brand}
            setSelectedOption={(value) => {
              setBrand(value);
              setModel(null);
            }}
            error={errorBrands}
            getOptionLabel={(option) => option.label}
            disableClearable
          />
        </Grid>
        <Grid item xs={12} md={5}>
          <Select
            className={clsx(classes.searchingSectionSelect, 'model-select')}
            label='Modelo'
            options={models.map((b) => ({
              label: b.name,
              value: b.id[0],
            }))}
            loading={loadingModels}
            selectedOption={model}
            setSelectedOption={(value) => setModel(value)}
            error={errorModels}
            getOptionLabel={(option) => option.label}
            disabled={!brand}
            disableClearable
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <div className={classes.catalogoBtnBuscar}>
          <Button fullWidth primary onClick={handleSubmit} id='catalogo-btn-buscar'>
            <span id='catalogo-btn-txt-buscar'>Buscar</span>
          </Button>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
