import React from 'react'
import FilterTagsTitle from '../../components/filterTags/filterTagsTitle.component'
import Tag from '../common/tag.component'
import { makeStyles } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  chip: {
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(1.5),
    backgroundColor: 'inherit',
    border: '1px solid #D1D1D1',
    maxWidth: '100%',
    height: '2rem',
  },
}))

const ChipList = ({ list = {}, handleClearFilter, handleClearAllFilters, showOnlyChips }) => {
  const listCount = Object.keys(list).length
  const classes = useStyles()

  return (
    <Box paddingTop={1}>
      {!showOnlyChips && <FilterTagsTitle onDeleteAll={handleClearAllFilters} count={listCount} />}
      <Box display="flex" flexWrap="wrap">
        {Object.entries(list).map(([key, value]) => (
          <Tag
            key={`${key}-${value}`}
            data-test-id="condicionTag"
            value={key}
            label={value}
            onDelete={() => handleClearFilter(key)}
            className={classes.chip}
          />
        ))}
      </Box>
    </Box>
  )
}

export default ChipList
