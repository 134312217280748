import React, { useEffect, useRef, useState } from 'react';
import BudgetClientModal from '../../components/budget/BudgetClientModal.component';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { useAppContext } from '../../context/AppContext';
import ResponsiveStatusModal from '../../components/common/ResponsiveStatusModal.component';
import { SUCCESS_MODAL_TEXT, DETAIL_MODAL_TEXT } from './constant';
import { GENERATE_LEAD_FROM_MASTER } from '../../mutations/sendLeadFromCatalogMaster';
import { useMutation } from '@apollo/react-hooks';

export const RequestQuoteForm = ({ open, setOpenForm, carMaster }) => {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { user } = useAppContext()

  const { profile: { role_name: roleName } = {} } = user;

  const [ userProfile, setUserProfile ] = useState({});
  const [ showStatusModal, setShowStatusModal ] = useState(false);
  const [ isSuccessful, setIsSuccessful ] = useState(false);

  const modalText = useRef({});

  const openInfoModal = () => {
    modalText.current = DETAIL_MODAL_TEXT;
  };

  useEffect(() => {
    openInfoModal();
  }, []);

  useEffect(() => {
    const { profile: { firstname: name, lastname: lastName, email, phone } = {} } = user;
    setUserProfile({ name, lastName, email, phone });
  }, [user]);

  const setError = () => {
    setIsSuccessful(false);
    setShowStatusModal(true);
  }

  const [ createLead, { loading: isLoading }] = useMutation(GENERATE_LEAD_FROM_MASTER, {
    onError: () => {
      setError();
    },
    onCompleted: (e) => {
      if (e?.generateLeadFromCatalogMaster?.success) {
        setIsSuccessful(true);
        setShowStatusModal(true);
      } else {
        setError();
      }
    },
  })

  const handleOnSubmit = (clientData) => {
    const client = {
      name: clientData.firstName,
      lastName: clientData.lastName,
      email: clientData.email,
      phone: clientData.phone
    }
    setUserProfile(client);
    setOpenForm(false);
    setShowStatusModal(true);
    const contactFrom = {
      car: {
        brand: { id: carMaster.brand.id[0], name: carMaster.brand.name },
        model: { id: carMaster.model.id[0], name: carMaster.model.name },
        version: { id: carMaster.version.id, name: carMaster.version.name },
        year: parseInt(carMaster.manufactYear?.to || 0),
        mileage: 0,
        isKmsCalculated: false,
        condition: 0,
        tipo_cotizador: 'Autos'
      },
      client,
    }

    createLead({ variables: { contactForm: contactFrom } });
  }

  return <>
    {open && <BudgetClientModal
      open={open}
      setModalClientOpen={ () => setOpenForm(false) }
      modalText={modalText}
      isMobile={isMobile}
      onSubmit={handleOnSubmit}
      userProfile={userProfile}
      roleName={roleName}
      isRequiredPhone
    />}
    <ResponsiveStatusModal
      success={isSuccessful}
      loading={isLoading}
      isMobile={isMobile}
      open={showStatusModal}
      loadingMessage={SUCCESS_MODAL_TEXT.loadingMessage}
      loadingLegend={SUCCESS_MODAL_TEXT.loadingLegend}
      successMessage={SUCCESS_MODAL_TEXT.successMessage}
      successLegend={SUCCESS_MODAL_TEXT.successLegend}
      errorMessage={SUCCESS_MODAL_TEXT.errorMessage}
      errorLegend={SUCCESS_MODAL_TEXT.errorLegend}
      errorTextBtn={SUCCESS_MODAL_TEXT.errorTextBtn}
      primarySuccessTextBtn={SUCCESS_MODAL_TEXT.primarySuccessTextBtn}
      secondarySuccessTextBtn={null}
      onPrimarySuccessClick={() => setShowStatusModal(false) }
      onSecondarySuccessClick={() => {} }
      onErrorBtnClick={() => {
        setShowStatusModal(false)
        setOpenForm(true)
      } }
      onClose={() => setShowStatusModal(false) }
    />
  </>
};
