import React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Text from '../../common/text.component';
import Button from '../../common/button.component';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  borderOnTop: {
    borderTop: `1px solid ${theme.palette.grayScale.g200}`,
  },
  borderBottom: {
    [theme.breakpoints.down('sm')]: {
      borderBottom: `1px solid ${theme.palette.grayScale.g200}`,
    }
  },
  image: {
    height: 150,
    width: 200
  },
  button: {
    width: '11rem',
    lineHeight: 0,
  },
  descriptionMobile: {
    fontSize: '0.875rem'
  }
}));

const DESCRIPTION = '¿Te gustaría comparar este auto con otros?';

const ComparadorCard = ({ goToCompare, borderOnTop }) => {
  const classes = useStyles();

  function handleOnClick (e) {
    e.preventDefault();
    goToCompare();
  }

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      className={clsx({ [classes.borderOnTop]:borderOnTop }, classes.borderBottom)}
      display="flex"
      flexDirection="column"
      alignItems="center"
      id="comparador-container"
      padding="2.5rem 0rem"
    >
      <Box textAlign="center" paddingBottom="2rem">
        <Text className={isMobile ? classes.descriptionMobile : ''} small normal bold>{DESCRIPTION}</Text>
      </Box>
      <Button data-test-id="boton" outline className={classes.button} onClick={handleOnClick}>
        Comparar
      </Button>
    </Box>
  );
};

export default ComparadorCard;
