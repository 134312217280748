import React, { useState } from 'react';
import { GET_CAR_MASTER } from '../../queries';
import { useQuery } from '@apollo/react-hooks';
import { CarPhotoGalery } from '../../components/CarDetail';
import { makeStyles } from '@material-ui/core';
import { theme as mainTheme } from '../../components/testUtils';
import { CatalogInfoCard } from './catalogInfoCard';
import { capitalizeEachWord } from '../../utils/typography.utils';
import { navigationRoutes } from '../../api/navigationRoutes.enum';
import Button from '../../components/common/button/Button.component';
import { goToCatalog, goToCompare } from '../../api/navigation';
import { CarHighlights } from './catalogCarHighlightsCard';
import { CatalogDescriptionCard } from './catalogDescriptionCard';
import clsx from 'clsx';
import Loading from '../../components/common/Loading.component';
import CatalogSpecsCard from '../../components/catalog-detail/CatalogSpecsCard';
import { RequestQuoteForm } from './requestQuoteForm';

const useStyle = makeStyles(() => ({
  container: {
    maxWidth: '832px',
    margin: '0 auto',
    '& .buttons': {
      margin: '3.5rem auto',
      maxWidth: '52.125rem',
      display: 'flex',
      '& > button': {
        marginRight: '1rem',
      },
      '& button': {
        flex: '1 1 0',
      },
      [mainTheme.breakpoints.down('sm')]: {
        margin: '2rem 0',
      },
    },
  },
  photoGallery: {
    margin: '3.5rem 0',
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonContainer: {
    [mainTheme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem',
      paddingLeft: '1rem',
      paddingRight: '1rem',
      '& button': {
        width: '100%',
      },
    },
  },
}));

const carrouselSettings = {
  responsive: [
    {
      breakpoint: mainTheme.breakpoints.values.sm,
      settings: {
        arrows: false,
      },
    },
  ],
};

export const CatalogDetailContainer = ({
  pageContext,
  setBreadcrumb = () => {},
}) => {
  const classes = useStyle();

  const [ productId, setProductId ] = useState(null);
  const [ imagesList, setImagesList ] = useState([]);
  const [ carMaster, setCarMaster ] = useState({});
  const [ openForm, setOpenForm ] = useState(false);

  const { loading: isLoading } = useQuery(GET_CAR_MASTER, {
    skip: !productId,
    variables: { id: productId },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (!data) return;
      const carMaster = data.carMaster;
      setImagesList(carMaster.images);
      setCarMaster(carMaster);
      setCrumbs(carMaster);
    },
  });

  React.useEffect(() => {
    const url = new URL(window.location.href);
    const id = url.searchParams.get('productId');
    setProductId(id);
  }, []);

  React.useEffect(() => {
    return () => {
      pageContext.breadcrumb.crumbs.splice(2);
    };
  }, [pageContext]);

  const setCrumbs = (car) => {
    const brandCrumb = {
      pathname: `${navigationRoutes.CATALOGO_AUTOS
      }?showResults=true&marcaLabel=${car?.brand?.name}&marcaValue=${car?.brand?.id[0]}`,
      crumbLabel: capitalizeEachWord(car?.brand?.name),
    };

    const brandModelCrumb = {
      pathname: `${navigationRoutes.CATALOGO_AUTOS}?showResults=true&marcaLabel=${
        car?.brand?.name}&marcaValue=${car?.brand?.id[0]}&modeloLabel=${
          car?.model?.name}&modeloValue=${car?.model?.id[0]}`,
      crumbLabel: capitalizeEachWord(car?.model?.name),
    };

    const versionCrumb = {
      pathname: '',
      crumbLabel: capitalizeEachWord(car?.version?.name),
    };

    setBreadcrumb([brandCrumb, brandModelCrumb, versionCrumb]);
  };
  const renderButton = () => {
    return carMaster.stockFisico ? (
      <Button
        type='primary'
        onClick={() => goToCatalog({ keyword: `${carMaster.brand.name} ${carMaster.model.name}` })}
        id='catalogo-detalle-btn-verVersionesEnStock-background'
      >
        <span id='catalogo-detalle-btn-verVersionesEnStock-txt'>Ver versiones en stock</span>
      </Button>
    ) : (
      <Button type="primary" onClick={() => setOpenForm(true)} id='catalogo-detalle-btn-solicitarPresupuesto-background'>
        <span id='catalogo-detalle-btn-solicitarPresupuesto-txt'>Solicitar presupuesto</span>
      </Button>
    );
  };

  return isLoading ? (
    <Loading />
  ) : (
    <><div className={classes.container}>
      <div className={classes.photoGallery}>
        <CarPhotoGalery
          photos={imagesList}
          thumbnails={imagesList.length > 1}
          settings={carrouselSettings}
        />
      </div>
      <div className={classes.infoContainer}>
        <CatalogInfoCard carMaster={carMaster} />
      </div>
      <div className={clsx(classes.buttonContainer, 'buttons')}>
        {renderButton()}
        <Button type="secondary" onClick={() => goToCompare(carMaster)} id='catalogo-detalle-btn-comparar-background'>
          <span id='catalogo-detalle-btn-comparar-txt'>Comparar</span>
        </Button>
      </div>
      <CatalogDescriptionCard description={carMaster.description} />
      <CarHighlights specialSpecs={carMaster?.specialSpecs} segment={carMaster.segment} />
      {carMaster.dataSheet && <CatalogSpecsCard pdfList={carMaster.pdf} dataSheet={carMaster.dataSheet} />}
    </div>
    <RequestQuoteForm open={openForm} setOpenForm={setOpenForm} carMaster={carMaster} /></>
  );
};
