import React from 'react';
import MuiTabs from '@material-ui/core/Tabs';
import { withStyles } from '@material-ui/core/styles';

const Tabs = withStyles({
  root: {
    borderBottom: '1px solid #e5e5e5',
    boxShadow: '0px 6px 6px -6px #00000029',
  },
  indicator: {
    height:3,
    flexGrow: 1,
  }
})((props) => <MuiTabs {...props} TabIndicatorProps={{ children: <span /> }} />);

export default Tabs;
