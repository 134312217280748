import { useQuery } from '@apollo/react-hooks';
import React from 'react';
import { GET_BRAND_LOGO } from '../../queries';
import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { Pill } from '../../components/common/Pill';
import { NEW_PALETTE } from '../../assets/theme';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    alignItems: 'start',
    width: '834px',
    height: '64px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: 'auto',
      gap: '8px',
    },
  },
  mobileContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    height: 'fit-content',
  },
  imgContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      height: '64px',
      width: '100px',
    },
  },
  brandLogo: {
    height: '64px',
    objectFit: 'contain',
    margin: 0,
  },
  textContainer: {
    height: '60px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginLeft: '16px',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '0',
    },
  },
  brandModelText: {
    fontWeight: 500,
    fontSize: '23px',
    lineHeight: '32px',
    letterSpacing: '.01em',
    color: NEW_PALETTE.neutral.gray07,
    [theme.breakpoints.down('sm')]: {
      fontWeight: 600,
      fontSize: '20px',
      lineHeight: '28px',
      letterSpacing: '.018em',
      textAlign: 'left',
    },
  },
  versionText: {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '.02em',
    color: NEW_PALETTE.neutral.gray06,
    [theme.breakpoints.down('sm')]: {
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '.02em',
      marginTop: '.5rem',
    },
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  yearManufacture: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    whiteSpace: 'nowrap',
    gap: '.5rem',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '.022em',
    color: NEW_PALETTE.neutral.gray07,
    [theme.breakpoints.down('sm')]: {
      marginTop: '1.5rem',
      marginLeft: '85px',
    },
  }
}));

export const CatalogInfoCard = ({ carMaster }) => {
  const classes = useStyles();
  const [ logo, setLogo ] = React.useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const brandName = carMaster?.brand?.name;

  useQuery(GET_BRAND_LOGO, {
    skip: !brandName,
    variables: { brand: brandName },
    onCompleted: logoData => {
      setLogo(logoData?.brandLogo);
    },
  });

  const manufactYear = <div className={classes.yearManufacture} >
    <div>Fabricación</div>
    <Pill label={`${carMaster?.manufactYear?.from} a ${carMaster?.manufactYear?.to}`} />
  </div>;

  return ( <div className={classes.mobileContent} >
    <div className={classes.container} >

      {logo && <div className={classes.imgContainer} >
        <img className={classes.brandLogo} src={logo} alt={brandName} />
      </div>}

      <div className={classes.infoContainer} >

        <div className={classes.textContainer} >

          <div className={classes.brandModelText} >
            {carMaster?.brand?.name} {carMaster?.model?.name}
          </div>

          <div className={classes.versionText} >
            {carMaster?.version?.name}
          </div>

        </div>

      </div>
      {!isMobile && manufactYear}
    </div>
    {isMobile && manufactYear}
  </div>
  );
};
