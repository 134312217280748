
/* Características destacadas */
export const CAR_HIGHLIGHTS = {
  POTEN: {
    name: 'Potencia máxima',
    unit: 'bhp'
  },
  VEMAX: {
    name: 'Velocidad máxima (km/h)',
    unit: 'km/h'
  },
  LLANT: {
    name: 'Llantas',
    concatenated: 'NEDEL',
    unitConcatenated: '\"',
  },
  TECHCOR: {
    name: 'Techo corredizo'
  },
  TECHPAN: {
    name: 'Techo vidriado panorámico'
  },
  SENSESTA: {
    name: 'Sensores de estacionamiento'
  },
  CAMES: {
    name: 'Cámara de estacionamiento'
  },
  LDPRO: {
    name: 'Llave de proximidad'
  },
  ARRAN: {
    name: 'Arranque sin llave'
  },
  TADAS: {
    name: 'Tecnología ADAS'
  },
  CDASI: {
    name: 'Capacidad de asientos'
  },
  CLIMAT: {
    name: 'Climatizador'
  },
  ISOFIX: {
    name: 'Ganchos Isofix'
  },
  DDSUE: {
    name: 'Despeje del suelo (cm)'
  },
  FILAS: {
    name: 'N° de filas de asientos'
  },
  PANTR: {
    name: 'Pantalla para pasajeros traseros'
  },
  VOLCA: {
    name: 'Volumen de carga',
    unit: 'm3'
  },
  QPUERTAS: {
    name: 'Cantidad de puertas'
  },
  TORQU: {
    name: 'Par/Torque máximo',
    unit: 'Nm'
  },
  CAPCA: {
    name: 'Capacidad de carga',
    unit: 'Kg'
  },
  CAPAR: {
    name: 'Capacidad de arrastre',
    unit: 'Kg'
  },
  SUDEL: {
    name: 'Suspensión delantera',
  },
  SUTRA: {
    name: 'Suspensión trasera',
  }
};

export const DETAIL_MODAL_TEXT = {
  title: 'Dejanos tus datos y nos contactaremos con vos',
  button: 'Enviar',
  buttonId: 'catalogo-form-btn-enviar-background',
  buttonTextId: 'catalogo-form-btn-enviar-txt',
}

export const SUCCESS_MODAL_TEXT = {
  loadingMessage: 'Enviando solicitud...',
  loadingLegend: 'Por favor, aguarde un momento mientras procesamos su solicitud.',
  successMessage: '¡Listo!',
  successLegend: 'En las próximas horas te contactaremos para asesorarte sobre el modelo que te interesa.',
  errorMessage: '¡Algo pasó!',
  errorLegend: 'Los datos no se enviaron pero podés volver a intentarlo.',
  errorTextBtn: 'Volver a intentar',
  primarySuccessTextBtn: 'Volver',
}
