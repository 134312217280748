import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import CustomTypography from '../../components/common/CustomTypography.component';
import Button from '../../components/common/button/Button.component';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyle = makeStyles((theme) => ({
  container: {
    width: '100%',
    paddingBottom: '3.5rem',
    [theme.breakpoints.down('sm')]: {
      padding: '1rem',
      paddingTop: 0,
      textAlign: 'left',
    },
    '& .button-container': {
      display: 'flex',
      justifyContent: 'flex-end',
    },
  },
  title: {
    paddingBottom: '1rem',
  },
  text: {
    textAlign: 'left',
    letterSpacing: '0.02em',
    fontWeight: 400,
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 6,
    fontSize: '1rem',
    lineHeight: '1.5rem',
    maxHeight: '9rem',
    '&.expanded': {
      maxHeight: 'none',
      overflow: 'visible',
      display: 'block',
    },
  },
}));

export const CatalogDescriptionCard = ({ description = '' }) => {
  const classes = useStyle();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [expanded, setExpanded] = useState(false);
  const textoRef = useRef(null);
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    if (textoRef.current) {
      const container = textoRef.current;
      const lineHeight = parseInt(window.getComputedStyle(container).lineHeight);
      const maxHeight = 6 * lineHeight;
      if (container.scrollHeight > maxHeight && isMobile) {
        setShowButton(true);
      }
    }
  }, [description, isMobile]);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  return description.trim().length < 5 ? (
    <></>
  ) : (
    <div className={classes.container}>
      {isMobile && (
        <CustomTypography variant={'subtitle3'} className={classes.title}>
          Descripción
        </CustomTypography>
      )}
      <div
        className={`${classes.text} ${expanded ? 'expanded' : ''}`}
        ref={textoRef}
      >
        {description}
      </div>
      {showButton && (
        <div className="button-container">
          <Button
            type="tertiary"
            onClick={toggleExpanded}
            endIcon={expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          >
            {expanded ? 'Ver menos' : 'Ver más'}
          </Button>
        </div>
      )}
    </div>
  );
};
