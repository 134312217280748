import React from 'react'
import Card from '../common/Card.component'
import { makeStyles, Box } from '@material-ui/core'
import Image from '../common/Image.component'
import noImage from '../../assets/images/no_car_img.png'
import CustomTypography from '../common/CustomTypography.component'

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    border: `1px solid ${theme.palette.grayScale.g300}`,
    width: '16.5rem',
    height: '22.6875rem',
    cursor: 'pointer',
    marginTop: '3px',
    marginBottom: '7px',
    '&:hover': {
      transform: 'translateY(-3px)',
      boxShadow: '0px 5px 7px rgba(0,0,0,0.16)',
    },
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '11rem',
    padding: '1rem 1rem 2rem 1.5rem',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  image: {
    width: '16.5625rem',
    height: '12.0625rem',
    objectFit: 'cover',
  },
  carInfo: {
    textAlign: 'left',
  },
}))

export default function CarInfoCard({ car = {}, onCardClick, id = '' }) {
  const classes = useStyles()
  const handleClick = () => onCardClick(car)
  const brandName = car?.brand?.name || '';
  const modelName = car?.model?.name || '';
  const info = `${brandName.charAt(0).toUpperCase() + brandName.slice(1).toLowerCase()} ${modelName.charAt(0).toUpperCase() + modelName.slice(1).toLowerCase()}`;

  return (
    <Card className={classes.mainContainer} onClick={handleClick} id={id}>
      <Box>
        {car.images.length ? (
          <Image image={car.images[0]} className={classes.image} />
        ) : (
          <Image image={noImage} className={classes.image} />
        )}
      </Box>
      <Box className={classes.infoContainer}>
        <CustomTypography variant="h6" className={classes.carInfo}>
          {info}
        </CustomTypography>
        <CustomTypography variant="body2" customColor='primaryAction'>
          Ver versiones
        </CustomTypography>
      </Box>
    </Card>
  )
}
