import gql from 'graphql-tag'

export const GET_FEATURED_CARS_BY_SEGMENT = gql`
  query FeaturedBySegment($segment: String) {
    featuredBySegment(segment: $segment) {
      version {
        name
      }
      segment {
        name
      }
      brand {
        id
        name
      }
      model {
        id,
        name
      }
      images
    }
  }
`
