import React from 'react';
import { CAR_HIGHLIGHTS } from './constant';
import { makeStyles } from '@material-ui/core';
import { NEW_PALETTE } from '../../assets/theme';
import { graphql, useStaticQuery } from 'gatsby';
import { GET_ATTRIBUTES_BY_SEGMENT } from '../../queries';
import { useQuery } from '@apollo/react-hooks';

const useStyle = makeStyles((theme) => ({
  container: {
    backgroundColor: NEW_PALETTE.primary.primary01,
    padding: '1rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    borderRadius: '8px',
    marginBottom: '1rem',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '1rem',
      marginRight: '1rem',
    },
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '28px',
    letterSpacing: '0.024em',
    color: NEW_PALETTE.neutral.gray07,
    textAlign: 'left',
  },
  iconBox: {
    backgroundColor: NEW_PALETTE.primary.primary02,
    width: '56px',
    height: '56px',
    borderRadius: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '1.2rem',
    '& img': {
      width: '24px',
      height: '24px',
    },
  },
  specsContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem',
    flexWrap: 'wrap',
    justifyContent: 'start',
    alignItems: 'start',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  specBox: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
    width: 'calc(50% - 8px)',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    gap: '4px',
    '& .label': {
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '20px',
      letterSpacing: '0.022em',
      color: NEW_PALETTE.neutral.gray06,
    },
    '& .value': {
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '0.02em',
      color: NEW_PALETTE.neutral.gray07,
    },
  }
}));

export const CarHighlights = ({ specialSpecs = [], segment }) => {

  const classes = useStyle();
  const data = useStaticQuery(query);

  const [ attrList, setAttrList ] = React.useState('');

  const { refetch, loading } = useQuery(GET_ATTRIBUTES_BY_SEGMENT, {
    skip: !segment?.name,
    variables: { segment: segment?.name },
    onCompleted: (data) => {
      if (!data) return;
      // attr = Características destacadas del producto seleccionado
      const attr = data?.attributesBySegment?.attributes || [];
      // specialSpecs = Características destacadas disponibles
      const list = specialSpecs.map((spec) => {
        const [key, value] = Object.entries(spec)[0];
        return {
          key,
          label: CAR_HIGHLIGHTS[key]?.name,
          value: (typeof value === 'boolean'
            ? (value ? 'Si' : 'No')
            : value) + (CAR_HIGHLIGHTS[key]?.unit ?? ''),
        };}
      );
      setAttrList(list.filter( item => attr.includes(item.key)));
    },
  });

  React.useEffect(() => {
    if (!segment) return;
    refetch();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [segment]);


  return !loading && attrList.length
    ? <div className={classes.container} >
      <div className={classes.titleContainer} >
        <div className={classes.title} >Características destacadas</div>
        <div className={classes.iconBox} >
          <img src={data.iconStar.publicURL} alt='icon-star' />
        </div>
      </div>
      <div className={classes.specsContainer} >
        {attrList.map((spec) => {
          return <div key={spec.key} className={classes.specBox} >

            <div className='label'>
              {spec.label}
            </div>

            <div className='value'>
              {spec.value}
            </div>

          </div>;
        })}
      </div>
    </div>
    : <></>;
};

const query = graphql`
  query {
    iconStar: file(sourceInstanceName: { eq: "icons" }, relativePath: { eq: "icon-star.svg" }) {
      relativePath
      publicURL
    }
  }  
`;
