import React from 'react'
import { makeStyles } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import clsx from 'clsx'

import Text from '../common/text.component'

const useStyles = makeStyles( (theme) => ({
  title: {
    fontWeight: 600,
    fontSize: '29px',
    lineHeight: '40px',
    letterSpacing: '0.02em',
    [theme.breakpoints.down('sm')]: {
      fontSize: '24px',
      lineHeight: '32px',
      letterSpacing: '0.01em',
    },
  },
  subtitile: {
    marginTop: '1rem',
    marginBottom: '1rem',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.02em',
  },
}))

const CatalogMasterTitle = () => {
  const classes = useStyles()

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <div className={classes.title} >
        Catálogo
      </div>
      <Text
        medium
        g600
        center
        className={clsx('catalog-master-container', classes.subtitile)}
      >
        Conocé a fondo cada auto con sus fichas técnicas y galerías de imágenes.
      </Text>
    </Box>
  )
}

export default CatalogMasterTitle
