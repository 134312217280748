import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Slide from '@material-ui/core/Slide';
import {
  DialogActions,
  DialogContent,
  Box,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';

import Link from '../common/Link.component';
import Logo from '../common/logo.component';
import Button from '../common/button.component';
import RadioButtonsGroup from '../common/RadioButtonsGroup.component';
import ExpandablePanel from '../common/expandablePanel.component';
import ChipList from './ChipList';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
    background: theme.palette.grayScale.g0,
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  grow: {
    margin: 'auto',
  },
  logo: {
    height: '2.5rem',
    width: '7rem',
    display: 'flex',
  },
  close: {
    position: 'absolute',
    color: theme.palette.secondary.main,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CatalogMasterFiltersComponent({
  open,
  onClose,
  handleFilterChange,
  filters,
  filtersNames,
  handleClearFilter,
  handleClearAllFilters,
  handleApplyFilters,
  selectedFilters
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();

  const SectionFilters = !filters ? <></> : filtersNames.map((filterName) => {
    return (
      <ExpandablePanel
        borderOnTop={false}
        heading={filters[filterName].name}
        isCatalogSection
        defaultExpanded
      >
        <RadioButtonsGroup
          data-test-id={filters[filterName].name}
          name={filters[filterName].name}
          value={filters[filterName].selected}
          onChange={(e) => handleFilterChange(filterName, e)}
          options={filters[filterName].options}
          itemsToShow={6}
          noOptionsMessage={filters[filterName].noOptionsMessage || 'No hay opciones disponibles.'}
        />
      </ExpandablePanel>
    );
  });

  if (isMobile) {
    return (
      <Dialog
        fullScreen
        open={open}
        onClose={onClose}
        TransitionComponent={Transition}
        scroll="paper"
      >
        {/* HEADER */}
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              className={classes.close}
              onClick={onClose}
              aria-label="close"
            >
              <ArrowBackIosIcon />
            </IconButton>
            <Grid className={classes.grow}>
              <Link to="/" className={classes.logo}>
                <Logo className={classes.logo} />
              </Link>
            </Grid>
          </Toolbar>
        </AppBar>

        <DialogContent dividers>
          <ChipList
            list={selectedFilters}
            handleClearFilter={handleClearFilter}
            handleClearAllFilters={handleClearAllFilters}
          />
          {SectionFilters}
        </DialogContent>

        <DialogActions>
          <Box display="flex" width="100%" justifyContent="center">
            <Button
              data-test-id="apply-filter-btn"
              id='catalogo-listadeautos-filtrar-btn-aplicarfiltros-background'
              onClick={() => {
                handleApplyFilters( open ? filters : null);
              }}
            >
              <span id='catalogo-listadeautos-filtrar-btn-aplicarfiltros-txt'>Aplicar Filtros</span>
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    );
  } else {
    return (
      <>
        {SectionFilters}
      </>
    );
  }
}
