import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress as Spinner } from '@material-ui/core';
import {
  SearchOutlined as Search,
  EditOutlined as Edit,
  DeleteOutlined as Delete,
  AddOutlined as Add,
  FilterListOutlined as Filter,
  CheckCircleOutlined as CheckCircle,
  CloseOutlined as Close,
  MoreVertOutlined as MoreVert,
  ShareOutlined as Share,
  VisibilityOutlined as VisibilityOn,
  VisibilityOffOutlined as VisibilityOff,
  ViewStreamOutlined as ListView,
  ViewModuleOutlined as GridView,
  ExitToAppOutlined as Logout,
  CheckBoxOutlineBlankOutlined as CheckboxUnchecked,
  CheckBoxOutlined as CheckboxChecked,
  RadioButtonCheckedOutlined as RadioSelected,
  RadioButtonUncheckedOutlined as RadioUnselected,
  GetAppOutlined as Download,
  InsertInvitationOutlined as Calendar,
  AccountCircleOutlined as Account,
  InsertDriveFileOutlined as Document,
  MenuOutlined as Menu,
  HomeOutlined as Home,
  ArrowDropUpOutlined as ArrowDropUp,
  ArrowDropDownOutlined as ArrowDropDown,
  KeyboardArrowUpOutlined as ChevronUp,
  KeyboardArrowDownOutlined as ChevronDown,
  KeyboardArrowLeftOutlined as ChevronLeft,
  KeyboardArrowRightOutlined as ChevronRight,
  FirstPageOutlined as FirstPage,
  LastPageOutlined as LastPage,
  ArrowUpwardOutlined as ArrowUp,
  ArrowDownwardOutlined as ArrowDown,
  ArrowBackOutlined as ArrowLeft,
  ArrowForwardOutlined as ArrowRight,
  InfoOutlined as Info,
  ErrorOutlineOutlined as Error,
  HelpOutlineOutlined as Help,
  ReportProblemOutlined as Alert,
  DoneOutlined as Done,
  Brightness1 as Color,
  Place,
  EmailOutlined as Email,
  WhatsApp,
  LocalAtmOutlined as LineasFinancieras,
  DashboardOutlined as Productos,
  ShoppingBasketOutlined as Reservas,
  SmsOutlined as SolicitudesDeMejora,
  DvrOutlined as Admin,
  DesktopWindowsOutlined as SitioWeb,
  ExpandMore as ExpandMore
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

export const ICONS = {
  SEARCH: 'search',
  EDIT: 'edit',
  DELETE: 'delete',
  ADD: 'add',
  FILTER: 'filter',
  CHECK_CIRCLE: 'check_circle',
  CLOSE: 'close',
  MORE_VERTICAL: 'more_vertical',
  SHARE: 'share',
  VISIBILITY_ON: 'visibility_on',
  VISIBILITY_OFF: 'visibility_off',
  LIST_VIEW: 'list_view',
  GRID_VIEW: 'grid_view',
  LOGOUT: 'logout',
  CHECKBOX_UNCHECKED: 'checkbox_unchecked',
  CHECKBOX_CHECKED: 'checkbox_checked',
  RADIO_SELECTED: 'radio_selected',
  RADIO_UNSELECTED: 'radio_unselected',
  DOWNLOAD: 'download',
  CALENDAR: 'calendar',
  ACCOUNT: 'account',
  DOCUMENT: 'document',
  MENU: 'menu',
  HOME: 'home',
  ARROW_DROP_UP: 'arrow_drop_up',
  ARROW_DROP_DOWN: 'arrow_drop_down',
  CHEVRON_UP: 'chevron_up',
  CHEVRON_DOWN: 'chevron_down',
  CHEVRON_LEFT: 'chevron_left',
  CHEVRON_RIGHT: 'chevron_right',
  FIRST_PAGE: 'first_page',
  LAST_PAGE: 'last_page',
  ARROW_UP: 'arrow_up',
  ARROW_DOWN: 'arrow_down',
  ARROW_LEFT: 'arrow_left',
  ARROW_RIGHT: 'arrow_right',
  INFO: 'info',
  ERROR: 'error',
  HELP: 'help',
  ALERT: 'alert',
  DONE: 'done',
  COLOR: 'color',
  PLACE: 'place',
  SPINNER: 'spinner',
  EMAIL: 'email',
  WHATSAPP: 'whatsapp',
  LINEAS_FINANCIERAS: 'lineas_financieras',
  PRODUCTOS: 'productos',
  RESERVAS: 'reservas',
  SOLICITUDES_DE_MEJORA: 'solicitudes_de_mejora',
  ADMIN: 'admin',
  SITIO_WEB: 'sitio_web',
  EXPAND_MORE: 'expand_more',
}

const sizesMap = {
  'small': 16,
  'medium': 24,
  'large': 48,
}

export const getIcon = (iconName) => {
  const iconsMap = {
    [ICONS.SEARCH]: Search,
    [ICONS.EDIT]: Edit,
    [ICONS.DELETE]: Delete,
    [ICONS.ADD]: Add,
    [ICONS.FILTER]: Filter,
    [ICONS.CHECK_CIRCLE]: CheckCircle,
    [ICONS.CLOSE]: Close,
    [ICONS.MORE_VERTICAL]: MoreVert,
    [ICONS.SHARE]: Share,
    [ICONS.VISIBILITY_ON]: VisibilityOn,
    [ICONS.VISIBILITY_OFF]: VisibilityOff,
    [ICONS.LIST_VIEW]: ListView,
    [ICONS.GRID_VIEW]: GridView,
    [ICONS.LOGOUT]: Logout,
    [ICONS.CHECKBOX_UNCHECKED]: CheckboxUnchecked,
    [ICONS.CHECKBOX_CHECKED]: CheckboxChecked,
    [ICONS.RADIO_SELECTED]: RadioSelected,
    [ICONS.RADIO_UNSELECTED]: RadioUnselected,
    [ICONS.DOWNLOAD]: Download,
    [ICONS.CALENDAR]: Calendar,
    [ICONS.ACCOUNT]: Account,
    [ICONS.DOCUMENT]: Document,
    [ICONS.MENU]: Menu,
    [ICONS.HOME]: Home,
    [ICONS.ARROW_DROP_UP]: ArrowDropUp,
    [ICONS.ARROW_DROP_DOWN]: ArrowDropDown,
    [ICONS.CHEVRON_UP]: ChevronUp,
    [ICONS.CHEVRON_DOWN]: ChevronDown,
    [ICONS.CHEVRON_LEFT]: ChevronLeft,
    [ICONS.CHEVRON_RIGHT]: ChevronRight,
    [ICONS.FIRST_PAGE]: FirstPage,
    [ICONS.LAST_PAGE]: LastPage,
    [ICONS.ARROW_UP]: ArrowUp,
    [ICONS.ARROW_DOWN]: ArrowDown,
    [ICONS.ARROW_LEFT]: ArrowLeft,
    [ICONS.ARROW_RIGHT]: ArrowRight,
    [ICONS.INFO]: Info,
    [ICONS.ERROR]: Error,
    [ICONS.HELP]: Help,
    [ICONS.ALERT]: Alert,
    [ICONS.DONE]: Done,
    [ICONS.COLOR]: Color,
    [ICONS.PLACE]: Place,
    [ICONS.SPINNER]: Spinner,
    [ICONS.EMAIL]: Email,
    [ICONS.WHATSAPP]: WhatsApp,
    [ICONS.LINEAS_FINANCIERAS]: LineasFinancieras,
    [ICONS.PRODUCTOS]: Productos,
    [ICONS.RESERVAS]: Reservas,
    [ICONS.SOLICITUDES_DE_MEJORA]: SolicitudesDeMejora,
    [ICONS.ADMIN]: Admin,
    [ICONS.SITIO_WEB]: SitioWeb,
    [ICONS.EXPAND_MORE]: ExpandMore,
  }

  return iconsMap[iconName]
}

const useStyles = makeStyles(theme => ({
  root: {
    width: 'fit-content',
    margin: 'auto',
    padding: '0.75rem',
  },
  iconMain: ({ dark, inheritColor }) => {
    if (inheritColor)
      return ({ color: 'inherit' })
    if (dark)
      return ({ color: theme.palette.grayScale.g250 })

    return({ color: theme.palette.grayScale.g400 })
  },
  actionable: ({ dark }) => ({
    color: dark ? 'white' : theme.palette.primary.main2,
    cursor: 'pointer',
  }),
  disabled: {
    color: theme.palette.grayScale.g700,
    cursor: 'not-allowed',
  }
}))

const Icon = ({
  theme = 'light',
  size = 'medium',
  iconName,
  onClick = null,
  disabled = false,
  inheritColor = false
}) => {
  const dark = theme === 'dark'
  const classes = useStyles({ dark, inheritColor })
  const iconClassNames = []

  if (onClick && !disabled) {
    iconClassNames.push(classes.actionable)
  }

  if (disabled) {
    iconClassNames.push(classes.disabled)
  } else {
    iconClassNames.push(classes.iconMain)
  }

  const isSpinner = iconName === ICONS.SPINNER
  const spinnerSize = isSpinner && sizesMap[size]
  console.log(spinnerSize)

  return (
    <div className={classes.root} onClick={!disabled && onClick}>
      <div className={iconClassNames}>
        {getIcon(iconName)}
      </div>
      {/* 
        <RenderIcon
        className={iconClassNames}
        fontSize={!isSpinner && size}
        size={isSpinner && spinnerSize}
        disabled={disabled}
      /> */}
    </div>
  )

}

Icon.propTypes = {
  theme: PropTypes.oneOf(['light', 'dark']),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  iconName: PropTypes.oneOf(Object.keys(ICONS)),
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  inheritColor: PropTypes.bool,
}

export default Icon
