import React from 'react';
import { Box } from '@material-ui/core';
import ExpandablePanel from '../../common/expandablePanel.component';
import Section from './Section.component';

export default function DataSheet({ car }) {
  const { dataSheet = [] } = car || {};
  return (
    <Box display='flex' flexDirection="column">
      {dataSheet.map((category, categoryIndex) => {
        let unifiedSection = [];
        category.sections.forEach(section =>{
          unifiedSection.push(...section.data);
        });
        return(
          <ExpandablePanel defaultExpanded={categoryIndex === 0} key={category.title} heading={category.title}>
            <Box display='flex' flexDirection="column">
              <Section  data={unifiedSection} />
            </Box>
          </ExpandablePanel>
        );}
      )
      }
    </Box>
  );
}
