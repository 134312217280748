import React from 'react';
import { makeStyles } from '@material-ui/core';
import { NEW_PALETTE } from '../../assets/theme';

const useStyle = makeStyles( () => ({
  pillContainer: {
    width: 'fit-content',
    height: '32px',
    borderRadius: '50px',
    border: `1px solid ${NEW_PALETTE.neutral.gray03}`,
    backgroundColor: NEW_PALETTE.neutral.white,
    padding: '6px 16px 6px 16px',
  },
  label: {
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '20px',
    color: NEW_PALETTE.neutral.gray07,
  }
})
);

export const Pill = ({ label }) => {
  const classes = useStyle();

  return (
    <div className={classes.pillContainer}>
      <div className={classes.label}>{label}</div>
    </div>
  );
};
