import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import { Box } from '@material-ui/core'
import clsx from 'clsx'

import Text from '../common/text.component'
import HiddenOn from '../common/HiddenOn.component'
import SearchBar from '../common/forStorybook/SearchBar.component'
import Button from '../common/button.component'
import { getIcon } from '../common/Icon.component'
import { downloadFile } from '../../utils/download.utils'

const SEARCH_START = 2

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      padding: '1rem 2rem',
    },
  },
  expansionPanel: {
    backgroundColor: '#F6F6F6',
    borderRadius: '0.5rem',
    boxShadow: 'none',
    marginBottom: '1rem',
    width: '100%',
    "&::before": {
      opacity: 0,
    }
  },
  expansionPanelContent: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, minmax(256px, 1fr))',
    gridTemplateRows: 'auto',
    gap: '1rem',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      flexWrap: 'wrap',
    },
  },
  expanded: {
    margin: '0 0 1rem 0 !important',
  },
  searchBar: {
    margin: '0',
    maxWidth: '26rem',
  },
  item: {
    width: '100%',
    textAlign: 'left',
    gap: '.5rem',
  },
  btnLink: {
    color: theme.palette.primary.main2,
    cursor: 'pointer',
    border: 'none',
    margin: '0',
    padding: '0',
    height: 'auto',
    fontSize: '1rem',
    fontWeight: '400',
    textAlign: 'left',
    '&:hover': {
      color: theme.palette.primary.dark,
    },
    [theme.breakpoints.down('sm')]: {
      lineHeight: '1.5rem',
    },
  },
  noOptions: {
    maxWidth: '26rem',
    position: 'absolute',
    padding: '24px',
    borderRadius: '25px',
    marginTop: '6px',
    zIndex: 1,
    textAlign: 'left',
  },
  searchContainer: {
    width: '26rem',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      marginTop: '1rem',
    },
  },
}))

const DownloadIcon = getIcon('download')
const ExpandMoreIcon = getIcon('expand_more')

const CatalogSpecsCard = ({ pdfList = [], dataSheet = [] }) => {
  const classes = useStyles()

  const specsListObj = dataSheet.find((obj) => obj.id === 'specs').sections
  const characteristicsListObj = dataSheet.find((obj) => obj.id === 'characteristics').sections
  const allSpecs = specsListObj.flatMap((obj) => obj.data).filter(spec => spec.label && spec.label.trim().length > 0)
  const allChars = characteristicsListObj.flatMap((obj) => obj.data)

  const [searchValue, setSearchValue] = useState('')
  const [specList, setSpecList] = useState(allSpecs)
  const [charsList, setCharsList] = useState(allChars)

  const onChangeSearch = (e) => {
    let value
    if (e === '') {
      value = ''
    } else {
      value = e.target.value
    }
    setSearchValue(value)
    if (value.length > SEARCH_START) {
      const filteredSpecs = allSpecs.filter((spec) => spec.label.toLowerCase().includes(value.toLowerCase()))
      const filteredChars = allChars.filter((char) => char.label.toLowerCase().includes(value.toLowerCase()))
      setSpecList(filteredSpecs)
      setCharsList(filteredChars)
    } else {
      setSpecList(allSpecs)
      setCharsList(allChars)
    }
  }

  const hasResults = specList.length || charsList.length

  return (
    <Box className={classes.root}>
      <Box
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        flexWrap="wrap"
        marginBottom="2.5rem"
      >
        <Text medium bold>
          Ficha técnica
        </Text>
        <Box className={classes.searchContainer}>
          <SearchBar
            autocomplete={false}
            receivedValue={searchValue}
            onChangeInput={onChangeSearch}
            placeholder={'Ingresá especificaciones o características'}
            id="catalogo-detalle-buscador"
            classNameTextfield={classes.searchBar}
          />
          {!hasResults && searchValue.length > SEARCH_START && (
            <Paper className={classes.noOptions} elevation={3}>
              <Text left light normal little>
                No encontramos la palabra "{searchValue}" que estás buscando
              </Text>
            </Paper>
          )}
        </Box>
      </Box>
      <ExpansionPanelCatalog
        expanded={searchValue.length > SEARCH_START && hasResults}
        itemList={hasResults ? specList : allSpecs}
        header="Especificaciones"
        falseValue="--"
      />
      <ExpansionPanelCatalog
        expanded={searchValue.length > SEARCH_START && hasResults}
        itemList={hasResults ? charsList : allChars}
        header="Características"
        falseValue="NO"
      />
      <Box mb="2rem" display="flex" flexDirection="column" alignItems="flex-start" gridGap="1rem">
        {Boolean(pdfList.length) && (
          <HiddenOn desktop>
            <Text g600 small semibolder>
              Ficha técnica en PDF
            </Text>
          </HiddenOn>
        )}
        {pdfList.map((pdf, i) => (
          <Box key={`pdf-${i}`}>
            <Button
              outline
              onClick={() => downloadFile(pdf.url, pdf.name)}
              className={classes.btnLink}
              startIcon={<DownloadIcon />}
              id={`catalogo-detalle-btn-fichaTecnicaPDF${i+1}`}
            >
              {pdf.name.split('.')[1]} - {Math.ceil(pdf.size / 1024)}KB
            </Button>
          </Box>
        ))}
      </Box>
    </Box>
  )
}

const ExpansionPanelCatalog = ({ expanded, falseValue, header = '', itemList = [] }) => {
  const [expand, setExpand] = useState(expanded)
  const classes = useStyles()

  return (
    <ExpansionPanel
      onChange={() => setExpand(!expand)}
      expanded={expanded || expand}
      classes={{ root: classes.expansionPanel, expanded: classes.expanded }}
    >
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <Text small>{header}</Text>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <Box className={clsx('expansionPanelContent', classes.expansionPanelContent)}>
          {itemList.map((spec, i) => (
            <Box
              key={`${spec.label}-${i}`}
              className={clsx('item', classes.item)}
              display="flex"
              flexDirection="column"
            >
              <Text small>{spec.label}</Text>
              <Text small semibolder>
                {spec.value ? (spec.value === true ? 'SI' : spec.value) : falseValue}
              </Text>
            </Box>
          ))}
        </Box>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}

export default CatalogSpecsCard
