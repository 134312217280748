import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import SegmentedCars from '../../components/catalogMaster/SegmentedCars.component'
import PropTypes from 'prop-types'
import { GET_FEATURED_CARS_BY_SEGMENT } from '../../queries/getFeaturedCarsBySegment'

const SegmentedCarsContainer = ({
  onSegmentClick = () => {},
  selectedSegment = {},
}) => {

  const { data, loading } = useQuery(GET_FEATURED_CARS_BY_SEGMENT, {
    skip: !selectedSegment,
    variables: {
      segment: selectedSegment?.label,
    },
    fetchPolicy: 'network-only',
  })

  const cars = data?.featuredBySegment || []

  return ( !selectedSegment ? <></> :
    <SegmentedCars
      cars={cars}
      loading={loading}
      onSegmentClick={onSegmentClick}
    />
  )
}

SegmentedCarsContainer.propTypes = {
  onSegmentClick: PropTypes.func,
  selectedSegment: PropTypes.object,
}
export default SegmentedCarsContainer
