import React from 'react'
import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import FilterListIcon from '@material-ui/icons/FilterList'

import ChipList from './ChipList'
import TextResults from '../common/TextResults'
import HiddenOn from '../common/HiddenOn.component'

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      padding: 0,
      width: 'calc(100vw - 2rem)',
    },
  },
  filterButton: {
    textTransform: 'none',
  },
  mobileBox: {
    marginTop: '2rem',
    padding: 0,
    height: '30px',
  },
  active: {
    color: theme.palette.primary.main,
  },
}))

const CatalogMasterFiltersHeader = ({
  total,
  onShowFilters,
  selectedFilters = {},
  handleClearFilter,
  handleClearAllFilters,
}) => {
  const classes = useStyles()
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <Box display="flex" flexDirection="column" className={classes.container} >
        <HiddenOn desktop>
          <Box
            display="flex"
            justifyContent="space-between"
            className={classes.mobileBox}
          >
            <Button
              color="primary"
              className={classes.filterButton}
              onClick={onShowFilters}
              data-test-id="show-filters-button"
              id='catalogo-btn-filtrar'
              endIcon={<FilterListIcon />}
            >
              Filtrar
            </Button>
            <TextResults total={total} />
          </Box>
        </HiddenOn>
        <ChipList
          list={selectedFilters}
          handleClearFilter={handleClearFilter}
          handleClearAllFilters={handleClearAllFilters}
          showOnlyChips={isMobile}
        />
      </Box>
    </>
  )
}

export default CatalogMasterFiltersHeader
