import React from 'react';
import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { Box } from '@material-ui/core';
import Text from '../../components/common/text.component';
import CarCard from '../../components/car-card/CarCard.component';
import Pagination from '../../components/common/Pagination.component';
import Loading from '../../components/common/Loading.component';
import { isBrowser } from '../../utils/ssr.utilis';
import TextResults from '../../components/common/TextResults';
import HiddenOn from '../../components/common/HiddenOn.component';
import { goToCatalogAutosDetail } from '../../api/navigation';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: '2rem',
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      gap: '1rem',
    },
  },
  loading: {
    marginTop: '10vh',
    marginBottom: '10vh',
  },
  pagination: {
    margin: 'auto',
    gridColumn: 'span 3',
    [theme.breakpoints.down('md')]: {
      gridColumn: 'span 2',
    },
  },
  results: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: '.5rem',
    marginTop: '1rem',
  },
  cardContainer: {
    width: '290px',
    [theme.breakpoints.down('sm')]: {
      width: '328px',
    },
  },
  paginatorContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '1rem',
    marginBottom: '1rem',
    width: '100%',
  },
  emptyStateText: {
    fontSize: '14px',
    fontWeight: '600',
    color: theme.palette.grayScale.g600,
    letterSpacing: '0.02em',
  }
}));

const CatalogMasterCards = ({
  cars,
  pagination,
  setPagination,
  loading,
  getDatamasterCars,
  total,
  selFilters = {},
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();

  const handlePaginationOnChange = (_, page) => {
    if (isBrowser) {
      window.scrollTo(0, 0);
    }
    setPagination({ ...pagination, actualPage: page });
    getDatamasterCars({
      variables: {
        ...selFilters,
        page,
      },
    });
  };

  if (loading) {
    return <Loading className={classes.loading} size={70} />;
  }

  if (cars.length === 0) {
    return (
      <Box display="flex" justifyContent="center" p={8}>
        <Text className={classes.emptyStateText}>No se encontraron resultados</Text>
      </Box>
    );
  }

  return (
    <>
      <HiddenOn mobile>
        <Box className={classes.results}>
          <TextResults total={total} />
        </Box>
      </HiddenOn>
      <Box className={classes.container}>
        {cars.map((car, i) => (
          <div key={`grid-${i}`} className={classes.cardContainer} onClick={() => goToCatalogAutosDetail(car.id)} >
            <CarCard
              key={`griditem-${i}`}
              car={car}
              compact={isMobile}
              showInfo={false}
              onStock={car.stockFisico}
              isComparador
            />
          </div>
        ))}
        <div className={classes.paginatorContainer}>
          <Pagination
            data-test-id="pagination"
            count={pagination.pages}
            page={parseInt(pagination.actualPage)}
            onChange={handlePaginationOnChange}
            className={classes.pagination}
          />
        </div>
      </Box>
    </>
  );
};

export default CatalogMasterCards;
