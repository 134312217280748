import React, { useRef, useEffect, useState } from 'react';
import CatalogMasterFiltersHeader from '../../components/catalogMaster/CatalogMasterFiltersHeader';
import CatalogMasterFiltersComponent from '../../components/catalogMaster/CatalogMasterFiltersComponent';
import { GET_BRANDS, GET_MODELS, GET_SEGMENTOS } from '../../queries';
import { useQuery } from '@apollo/react-hooks';
import { CATALOG_FILTERS_SETTINGS } from './CatalogMasterFilters.settings';

const FILTERS_NAMES = ['segments', 'brands', 'models', 'fuel', 'transmission'];

const getLabel = (filter = {}, selectedId) => {
  const selectedOption = filter.options.find((option) => option.value === selectedId)
  return selectedOption?.label || null;
}

const CatalogMasterFilters = ({ total, onChange = () => {}, clearFlag, setClearFlag, selFilters, setSelFilters }) => {

  const [ openDialog, setOpenDialog ] = useState(false);

  const [filters, setFilters] = useState(CATALOG_FILTERS_SETTINGS);
  
  const updateFilters = ( filterName, filterKey, value) =>
    setFilters( prev => ({ ...prev, [filterName]: { ...prev[filterName], [filterKey]: value } }));

  const previousFilters = useRef(filters)
  const { data: segmentsData, refetch: getSegments } = useQuery(GET_SEGMENTOS,
    {
      notifyOnNetworkStatusChange: true,
      variables: { all: true },
      onCompleted: ( success ) => {
        if ( success ) {
          const newSegments = segmentsData.segments.map( seg => ({ label: seg.name, value: seg.name }));
          updateFilters('segments', 'options', newSegments);
        }
      }
    });

  const { data: brandsData, refetch: getBrands } = useQuery(GET_BRANDS,
    {
      notifyOnNetworkStatusChange: true,
      variables: { published: true, segment: getLabel(filters.segments, filters.segments.selected) },
      onCompleted: ( success ) => {
        if (success) {
          const newBrands = brandsData.brands.map( brand => ({ label: brand.name, value: brand.id[0] }));
          updateFilters('brands', 'options', newBrands);
        }
      }
    });
  const { data: modelsData, refetch: getModels } = useQuery(GET_MODELS,
    {
      variables: {
        published: true,
        brandId: filters.brands.selected,
        segment: getLabel(filters.segments, filters.segments.selected)
      },
      onCompleted: ( success ) => {
        if (success) {
          const newModels = modelsData.models.map( model => ({ label: model.name, value: model.id[0] }));
          updateFilters('models', 'options', newModels);
        }
      },
    });

  useEffect(() => {
    getBrands();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.segments.selected]);

  useEffect(() => {
    getModels();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.brands.selected]);

  useEffect(() => {
    if (clearFlag) {
      handleClearAllFilters()
      setClearFlag(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearFlag])

  useEffect(() => {
    if (!openDialog) {
      previousFilters.current = filters;
    }
  }, [filters, openDialog])

  useEffect(() => {
    const url = window.location.href;
    const fullParams = new URLSearchParams(url);
    const params = {
      segmentLabel: fullParams.get('segmentoLabel'),
      segmentValue: fullParams.get('segmentoValue'),
      brandsLabel: fullParams.get('marcaLabel'),
      brandsValue: fullParams.get('marcaValue'),
      modelsLabel: fullParams.get('modeloLabel'),
      modelsValue: fullParams.get('modeloValue'),
    };

    let filtersPreSelected = {}

    if (params.segmentValue) {
      updateFilters('segments', 'selected', params.segmentLabel);
      filtersPreSelected = { ...filtersPreSelected, segments: params.segmentLabel }
    }
    if (params.brandsValue) {
      updateFilters('brands', 'selected', params.brandsValue);
      filtersPreSelected = { ...filtersPreSelected, brands: params.brandsLabel }
    }
    if (params.modelsValue) {
      updateFilters('models', 'selected', params.modelsValue);
      filtersPreSelected = { ...filtersPreSelected, models: params.modelsLabel }
    }

    setSelFilters(filtersPreSelected);
    onChange(filtersPreSelected);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFilterChange = (filterName, e) => {
    const selectedFilterOption = e.target.value;
    updateFilters(filterName, 'selected', selectedFilterOption);
    if (filterName === 'segments') {
      updateFilters('brands', 'selected', null);
      delete selFilters.brands;
      updateFilters('models', 'selected', null);
      delete selFilters.models;
    }
    if ( filterName === 'brands' ) {
      updateFilters('models', 'selected', null);
      delete selFilters.models;
    }

    const labelFilter = getLabel(filters[filterName], selectedFilterOption);
    setSelFilters({ ...selFilters, [filterName]: labelFilter })
    onChange({ ...selFilters, [filterName]: labelFilter })
  };

  // Función para borrar el valor seleccionado de un filtro específico
  const handleClearFilter = (filterName) => {
    delete selFilters[filterName];
    updateFilters(filterName, 'selected', null);
    if (filterName === 'segments') {
      updateFilters('brands', 'selected', null);
      delete selFilters.brands;
      updateFilters('models', 'selected', null);
      delete selFilters.models;
    }
    if ( filterName === 'brands' ) {
      updateFilters('models', 'selected', null);
      delete selFilters.models;
    }
    setSelFilters(selFilters)
    onChange(selFilters);
  };

  const handleClearAllFilters = () => {
    setFilters(CATALOG_FILTERS_SETTINGS);
    setSelFilters({});
    getSegments();
    getBrands();
    onChange({});
  };

  const handleApplyFilters = () => {
    setOpenDialog(false)
  };

  const onClose = () => {
    let previousSelected = {}

    setFilters(previousFilters.current);
    for (const key in previousFilters.current) {
      if (previousFilters.current[key].selected) {
        previousSelected[key] = getLabel(previousFilters.current[key], previousFilters.current[key].selected)
      }
    }
    setSelFilters(previousSelected)
    onChange(previousSelected)
    setOpenDialog(false);
  }

  return (
    <div>
      {/* header */}
      <CatalogMasterFiltersHeader
        total={total}
        onShowFilters={() => setOpenDialog(true)}
        selectedFilters={selFilters}
        handleClearFilter={handleClearFilter}
        handleClearAllFilters={handleClearAllFilters}
        dialogOpen={openDialog}
      />
      {/* lista de filtros  */}
      <CatalogMasterFiltersComponent
        filters={filters}
        filtersNames={FILTERS_NAMES}
        open={openDialog}
        onClose={onClose}
        handleFilterChange={handleFilterChange}
        handleClearFilter={handleClearFilter}
        handleClearAllFilters={handleClearAllFilters}
        handleApplyFilters={handleApplyFilters}
        selectedFilters={selFilters}
      />
    </div>
  );
};

export default CatalogMasterFilters;
