import React, { useState } from 'react';
import Text from '../../components/common/text.component';
import { goToCatalogAutosList } from '../../api/navigation';
import CustomTypography from '../../components/common/CustomTypography.component';
import MoreModelsSectionContainer from '../../containers/catalogMaster/MoreModelsSection.container';
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';
import { SearchBarSection } from './searchBarSection';
import { SearchingSection } from './searchingSection';

const SUB_TITLE = 'Conocé a fondo cada auto con sus fichas técnicas y galerías de imágenes.'

export const CatalogMasterContainerMain = ({ location, classes }) => {
  const [brand, setBrand] = useState(null);
  const [model, setModel] = useState(null);
  const [searchValue, setSearchValue] = useState('');

  const handleSubmit = () => {
    goToCatalogAutosList({
      marca: brand,
      modelo: model,
    });
  };

  return (
    <Grid container alignItems="center" direction="column">
      <CustomTypography variant="h3">Catálogo</CustomTypography>

      <Text
        medium
        g600
        center
        className={clsx('catalog-master-container', classes.subtitile)}
      >
        {SUB_TITLE}
      </Text>

      <SearchBarSection
        classes={classes}
        location={location}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
      />

      <SearchingSection
        classes={classes}
        handleSubmit={handleSubmit}
        brand={brand}
        setBrand={setBrand}
        model={model}
        setModel={setModel}
      />

      <MoreModelsSectionContainer classes={classes} />
    </Grid>
  );
};
