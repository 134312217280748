import React, { useState } from 'react'
import { GET_SEGMENTOS } from '../../queries'
import MoreModelsSection from '../../components/catalogMaster/MoreModelsSection.component'
import { useQuery } from '@apollo/react-hooks'
import { goToCatalogAutosList } from '../../api/navigation'
const DEFAULT_SEGMENT = 'suv'

const sortSegments = (segments) => {
  const orden = {
      "Hatchback": 0,
      "Hatchback Plus": 1,
      "Sedán": 2,
      "Sedán Plus": 3,
      "SUV": 4,
      "SUV Plus": 5,
      "Deportivo": 6,
      "Pick Up": 7,
      "Pick Up Liviana": 8,
      "Utilitario": 9,
      "Familiar": 10,
      "Minivan/Minibus": 11,
      "Camión": 12,
      "Bus": 13,
      "Otro": 14
  };

  return segments.sort((a, b) => {
      return orden[a.name] - orden[b.name];
  });
}
const MoreModelsSectionContainer = ({ classes }) => {
  const [segment, setSegment] = useState(null)
  const handleSegmentClick = () => {
    goToCatalogAutosList({
      segmento: segment,
    })
  }
  const { data, loading } = useQuery(GET_SEGMENTOS, {
    variables: { all: true },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data?.segments.length) {
        data.segments = sortSegments(data.segments)
        const suvSegment = data.segments.find((segment) => segment.type === DEFAULT_SEGMENT)
        setSegment({ label: suvSegment.name, value: suvSegment.type })
      }
    },
  })
  
  const { segments = [] } = data || {}
  return (
    <MoreModelsSection
      selectedSegment={segment}
      setSegment={setSegment}
      segments={segments}
      onSegmentClick={handleSegmentClick}
      loadingSegments={loading}
      classes={classes}
    />
  )
}

export default MoreModelsSectionContainer
